import React, { useCallback } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { useIntl } from "react-intl"
import Gallery from "react-photo-gallery"
import Layout from "../components/layout"
import SEO from "../components/seo"

const JustifiedGallery = ({ pageContext }) => {
  const { name, title, images } = pageContext
  const intl = useIntl()
  const lang = intl.locale
  const galleryUrl = lang !== "en" ? `/${lang}/${name}` : `/${name}`

  const photos = images.map(
    ({
      id,
      file: {
        details: {
          image: { height, width },
        },
      },
      fluid,
    }) => ({
      id,
      fluid,
      width: 1,
      height: height / width,
    })
  )

  const imageRenderer = useCallback(
    ({ index, photo }) => (
      <div css={css``} key={index}>
        <Link to={`${galleryUrl}/${photos[index].id}`}>
          <Img
            css={css`
              height: ${photo.height}px;
              width: ${photo.width}px;
              margin: 2px;
            `}
            fluid={photos[index].fluid}
            // fixed={photos[index].fixed}
          />
        </Link>
      </div>
    ),
    []
  )

  return (
    <Layout title={title}>
      <SEO title="Home" />
      <div
        dir="ltr"
        css={css`
          padding: 20px 0px;
          margin: 0px auto;
        `}
      >
        <Gallery
          photos={photos}
          renderImage={imageRenderer}
          targetRowHeight="420"
        />
      </div>
    </Layout>
  )
}

JustifiedGallery.propTypes = {
  pageContext: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
}

export default JustifiedGallery
